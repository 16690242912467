
/// base ///////////////////////////////////////////////////////////////////////

@import '~@angular/material/theming';

@import '~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css';
@import '~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css';

@include mat-core();
@include angular-material-theme(
    mat-light-theme(mat-palette($mat-gray), mat-palette($mat-gray))
);

.pswp__bg {
    background: white !important;
}

* {
    border: 0;
    margin: 0;
    padding: 0;

    resize: none;
    outline: none;

    user-select: none;
    box-sizing: border-box;

    -webkit-font-smoothing: antialiased;
            font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;

    background: transparent;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
}

ul,
ol,
li {
    list-style: none;
}

html,
body {
    font-size: 10px;
    line-height: 10px;
    font-family: 'Kohinoor';

    scroll-behavior: smooth;
}

input,
select,
button,
textarea {
    user-select: auto;
    font-family: 'Kohinoor';
}

input::placeholder,
textarea::placeholder {
    color: inherit;
}


/// scrolls ////////////////////////////////////////////////////////////////////

/*::-webkit-scrollbar {
    width:.3rem;
    height:.3rem;
    position:absolute
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    transition: .35s ease;
    background: rgba(0,0,0,.1);
}

:hover::-webkit-scrollbar-thumb {
    background: #ff6228;
}*/


/// z-index ////////////////////////////////////////////////////////////////////

.z-top { z-index: 100; }
.z-medium { z-index: 10; }
.z-bottom { z-index: 1; }


/// font styles ////////////////////////////////////////////////////////////////

.f-thin { font-weight: 100; }
.f-light { font-weight: 300; }
.f-regular { font-weight: 400; }
.f-medium { font-weight: 600; }
.f-bold { font-weight: 800; }

.f-lower { text-transform: lowercase; }
.f-upper { text-transform: uppercase; }

.f-right { text-align: right; }
.f-center { text-align: center; }


/// render styles //////////////////////////////////////////////////////////////

.d-flex { display: flex; }
.d-none { display: none; }
.d-block { display: block; }
.d-inline { display: inline; }

.d-inline.d-flex { display: inline-flex; }
.d-inline.d-block { display: inline-block; }

.d-flex.wrap { flex-flow: wrap; }
.d-flex.column { flex-direction: column; }
.d-flex.align-top { align-items: start; }
.d-flex.align-center { align-items: center; }
.d-flex.justify-center { justify-content: center; }

.d-flex > * {
    flex-grow: 0;
    flex-shrink: 0;
}

.d-flex > .grow { flex-grow: 1; }
.d-flex > .shrink { flex-shrink: 1; }

.for-display { speak: none; }
.for-sreader {
    width: 0 !important;
    height: 0 !important;

    font-size: 0 !important;
    line-height: 0 !important;

    display: block !important;
    overflow: hidden !important;
}


/// containers /////////////////////////////////////////////////////////////////

.container {
    margin: auto;
    max-width: 1380px;
}


@media (max-width: 1450px) {
    
    html,
    body {
        font-size: 9px;
        line-height: 9px;
    }

}